import { useQuery } from '@tanstack/react-query'
import { getCategories } from 'api/categories'
import { STALE_TIME } from 'helpers/query'
import { useCallback } from 'react'
import { emptyCategories } from 'schemas/categories'

export function useCategoriesQuery(reactQueryOptions) {
  return useQuery({
    placeholderData: emptyCategories,
    staleTime: STALE_TIME,
    queryKey: ['categories'],
    queryFn: getCategories,
    ...reactQueryOptions,
  })
}

export function useGroupTagsByCategoryQuery(tags, reactQueryOptions) {
  const { data = [] } = useCategoriesQuery({
    select: useCallback(
      (categories) => {
        // memoize map creation to avoid nested loops and reduce time complexity
        const tagsMap = categories.reduce((acc, category) => {
          const { tags, ...cat } = category

          tags.forEach((tag) => {
            acc[tag.id] = {
              ...tag,
              category: cat,
            }
          })

          return acc
        }, {})

        // then group tags by category name
        return tags.reduce((acc, tag) => {
          const mappedTag = tagsMap[tag.id]
          const categoryName = mappedTag?.category?.original_name
          if (!categoryName) return acc

          return {
            ...acc,
            [categoryName]: (acc[categoryName] || []).concat(mappedTag),
          }
        }, {})
      },
      [tags],
    ),
    ...reactQueryOptions,
  })

  return data
}

function useTagsByCategoryNameQuery(name, reactQueryOptions) {
  const { data = [] } = useCategoriesQuery({
    select: useCallback(
      (categories) => categories.find((category) => category.original_name === name)?.tags || [],
      [name],
    ),
    ...reactQueryOptions,
  })

  return data
}

export function useAmenitiesQuery() {
  return useTagsByCategoryNameQuery('amenities')
}

export function useDietsQuery() {
  return useTagsByCategoryNameQuery('diets')
}

export function useFoodsQuery() {
  return useTagsByCategoryNameQuery('foods')
}
